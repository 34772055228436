<template>
  <div class="row w-100 m-0 text-start">
    <div class="col-12 mb-2">
      <h2>Configuration</h2>
      <br>
    </div>
    <div class="row text-center justify-content-center">
      <div class="d-flex align-items-start col-12">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="height: 100%;border-right: 1px solid #eee;">
          <button
            class="nav-link ws-nowrap active"
            id="v-pills-external-apis-tab"
            data-toggle="pill"
            href="#v-pills-external-apis"
            type="button"
            role="tab"
            aria-controls="v-pills-external-apis"
            aria-selected="true"
          >
            External API's
          </button>
        </div>
        <div class="card tab-content w-100" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-external-apis" role="tabpanel" aria-labelledby="v-pills-external-apis-tab">
            <ExternalApisTab></ExternalApisTab>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalApisTab from './components/ExternalApisTab.vue'
export default {
  components: {
    ExternalApisTab
  },
  setup() {},
}
</script>
