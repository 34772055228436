<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '40vw' }"
    @hide="$emit('close')"
    header="New External API"
  >
    <form class="row" @submit.prevent="() => {}">
      <div class="col-6">
        <div class="form-group">
          <label for="">Name</label>
          <input type="text" class="form-control" :class="v.name.$error ? 'is-invalid' : ''" v-model="v.name.$model" />
          <p class="invalid-feedback d-block m-0">{{ v.name.$errors[0]?.$message }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">URL</label>
          <input
            type="text"
            class="form-control"
            v-model="form.url"
          />
        </div>
      </div>

      <div class="col-8">
        <div class="form-group">
          <label for="">Token</label>
          <input
            type="text"
            class="form-control"
            v-model="form.token"
          />
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="">Expires At</label>
          <input type="date" class="form-control" v-model="form.expires_at" />
        </div>
      </div>
    </form>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
      <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { required, email, helpers, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import { useToast } from 'primevue/usetoast'

import configurationService from '../../../services/configuration.service'
import Dialog from 'primevue/dialog';

export default {
  components: { Dialog },
  props: {
    showModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const store = useStore()
    const toast = useToast()

    const form = reactive({
      name: '',
      url: '',
      token: '',
      expires_at: '',
    })

    onMounted(() => {
    })

    // Validation
    const rules = computed(() => ({
      name: { required },
    }))

    const v = useVuelidate(rules, form)

    const reset = () => {
      form.name = ''
      form.url = ''
      form.token = ''
      form.expires_at = ''
      v.value.$reset()
    }

    const handleSubmit = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return
      const externalApi = {
        ...form,
      }
      try {
        const newAPi = await configurationService.createExternalApi(externalApi)
        reset()
        emit('save', newAPi)
      } catch (error) {
        console.log(error)
        emit('error')
      }
    }

    return {
      handleSubmit,
      reset,
      form,
      v,
    }
  },
}
</script>
