import axios from '@/config/axios'

class ConfigurationService {
  async fetchAllExternalApis() {
    try {
      const resp = await axios.get('/external_api')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchExternalApi(id) {
    try {
      const resp = await axios.get(`/external_api/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createExternalApi({ name, url, token, expires_at }) {
    try {
      const resp = await axios.post('/external_api', { name, url, token, expires_at })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateExternalApi(id, { name, url, token, expires_at }) {
    try {
      const resp = await axios.put(`/external_api/${id}`, { name, url, token, expires_at })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteExternalApi(id) {
    try {
      const resp = await axios.delete(`/external_api/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new ConfigurationService()
