<template>
  <ModalCreateExternalApi :showModal="showCreate" @close="showCreate = false" @save="addExternalApi" @error="error"></ModalCreateExternalApi>
  <div class="row">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <h4>External API's</h4>

      <div class="dropdown d-flex">
        <button class="btn btn-info btn-sm" type="button" @click="showCreate = true">
          <i class="fas fa-plus me-2"></i>Add External API
        </button>
      </div>
    </div>

    <div class="col-12 mt-4 w-100">
      <div class="row justify-content-end mb-3">
        <div class="col-6 m-0 p-0">
          <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
        </div>
      </div>

      <DataTable
        :value="externalApis"
        responsiveLayout="scroll"
        removableSort
        editMode="row"
        v-model:editingRows="editingRows"
        :loading="loading"
        @row-edit-save="onRowEditSave"
        v-model:filters="filters"
      >
        <Column field="name" header="Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" autofocus />
          </template>
        </Column>

        <Column field="url" header="URL" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" autofocus />
          </template>
        </Column>

        <Column field="token" header="Token" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" autofocus />
          </template>
        </Column>

        <Column field="expires_at" header="Expires At" :sortable="true">
          <template #body="{ data }">
            {{ data.expires_at != null ? formatDate(data.expires_at) : 'Not assigned' }}
          </template>
          <template #editor="{ data, field }">
            <input type="date" class="form-control" v-model="data[field]" autofocus />
          </template>
        </Column>

        <Column field="created_at" header="Created" :sortable="true">
          <template #body="{ data }">
            {{ formatLocalDate(data.created_at) }}
          </template>
        </Column>

        <Column field="updated_at" header="Updated" :sortable="true">
          <template #body="{ data }">
            {{ formatLocalDate(data.updated_at) }}
          </template>
        </Column>

        <Column :rowEditor="true"></Column>
        <Column>
          <template #body="{ data }">
            <button class="p-row-editor-init p-link" @click="(event) => deleteExternalApi(event, data)"><i class="pi pi-trash"></i></button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ConfirmPopup from 'primevue/confirmpopup'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'

import Configuration from '../../../services/configuration.service.js'
import { useDatatable } from '../../../hooks/useDatatable.js'

import ModalCreateExternalApi from './ModalCreateExternalApi.vue'

export default {
  components: {
    DataTable,
    Column,
    ConfirmPopup,
    ModalCreateExternalApi
  },
  setup() {
    const externalApis = ref([])
    const op = ref()
    const name = ref('')
    const toast = useToast()
    const confirm = useConfirm()
    const { loading, filters, editingRows } = useDatatable()
    const showCreate = ref(false)

    onMounted(() => {
      getExternalApis()
    })

    const getExternalApis = async () => {
      loading.value = true
      externalApis.value = await Configuration.fetchAllExternalApis()
      loading.value = false
    }

    const addExternalApi = async (newExternalApi) => {
      externalApis.value.push(newExternalApi)
      showCreate.value = false
      toast.add({ severity: 'success', summary: 'Success', detail: 'API added', life: 3000 })
    }

    const deleteExternalApi = (event, externalApi) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Do you want to delete this API?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          await Configuration.deleteExternalApi(externalApi.id)
          externalApis.value = externalApis.value.filter((a) => a.id != externalApi.id)
          toast.add({ severity: 'success', summary: 'Deleted', detail: `${externalApi.name} deleted`, life: 3000 })
        },
      })
    }

    const formatLocalDate = (date) => {
      return new Date(date).toLocaleDateString()
    }

    const formatDate = (date) => {
      let newDate = date.split('-')
      return newDate[2] + '/' + newDate[1] + '/' + newDate[0]
    }

    const onRowEditSave = async (event) => {
      const { newData } = event
      const updated = await Configuration.updateExternalApi(newData.id, newData)
      externalApis.value = externalApis.value.map((a) => (a.id == updated.id ? updated : a))
    }

    const error = () => {
      toast.add({ severity: 'error', summary: 'Something went wrong', detail:'Unable to create API', life: 3000 })
    }


    return {
      externalApis,
      formatLocalDate,
      formatDate,
      op,
      addExternalApi,
      name,
      loading,
      filters,
      editingRows,
      onRowEditSave,
      deleteExternalApi,
      showCreate,
      error
    }
  },
}
</script>
